import {
  Heading,
  TabsGeneric,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import {
  PlanDataShape,
  PlansTab,
} from '../components/templates/PlansAndPricesElement/PlansTab';
import { PricesTab } from '../components/templates/PlansAndPricesElement/PricesTab';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from '../utils/helpers';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

export interface PlanCategoryInterface {
  title: string;
  description: string;
  start?: number;
}

export interface PlanOptionInterface {
  title: string;
  values: (string | boolean)[];
}

export interface PlanInterface {
  title: string;
  description: string;
  category: PlanCategoryInterface[];
  options: PlanOptionInterface[];
}

export interface PlanAddons {
  title: string;
  options: SelectOptionShape[];
}

export function PlanCategory(plan: PlanCategoryInterface) {
  const appInfo = useAppInfo();
  return (
    <div className="flex flex-col gap-4 rounded-md border border-gray-300 px-12 py-6 text-center">
      <Heading kind="h5">{plan.title}</Heading>
      {plan.start !== undefined && (
        <div className="font-caudex flex items-center gap-2 text-2xl">
          <span className="text-sm">from</span>{' '}
          {getCurrencyFormat(plan.start, appInfo.currency)}
        </div>
      )}
      <Heading kind="p">{plan.description}</Heading>
    </div>
  );
}

export function LabelSelect({
  label,
  options,
}: {
  label: string;
  options: SelectOptionShape[];
}) {
  return (
    <div className="font-nunito flex w-fit items-center gap-2 rounded-md bg-white px-4 py-2 pr-0">
      <span className="flex text-gray-500">{label}</span>-
      <select className="font-nunito h-auto w-auto border-0 bg-transparent p-0 font-bold">
        {options.map((option) => (
          <option key={'option-' + label + '' + option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

const gridMap: Record<number, string> = {
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
};

export function PlansHeader({
  title,
  description,
  plans,
  planAddons,
}: {
  title: string;
  description: string;
  plans: PlanCategoryInterface[];
  planAddons?: PlanAddons[];
}) {
  return (
    <>
      <div className="grid grid-cols-[360px_1fr] gap-4">
        <div className="bg-primary-600 flex flex-col gap-4 rounded-md border border-gray-300 p-4">
          <Heading kind="h2" className="text-white">
            {title}
          </Heading>
          {planAddons &&
            planAddons.map((addons) =>
              LabelSelect({ label: addons.title, options: addons.options })
            )}
          <Heading kind="p" className="text-white">
            {description}
          </Heading>
        </div>
        <div className={`grid ${gridMap[plans.length]} gap-4`}>
          {plans.map((plan) => PlanCategory(plan))}
        </div>
      </div>
    </>
  );
}

export function PlansGrid({ plans }: { plans: PlanDataShape }) {
  return (
    <div className="grid grid-cols-[360px_1fr] gap-4">
      <div className="flex flex-col gap-4">
        <div className="font-caudex rounded-md bg-white py-2 px-4 text-xl font-bold">
          Term
        </div>
        <div className="flex flex-col rounded-md border border-gray-300 bg-white">
          {plans.header.map((item) => (
            <div className="font-caudex flex border-b border-gray-200 px-4 py-3 text-sm uppercase text-gray-800">
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className={`grid grid-cols-4 gap-4`}>
        {plans.options.map((option) => (
          <>
            <div className="flex flex-col gap-4">
              <div className="font-caudex rounded-md bg-white py-2 px-4 text-xl font-bold">
                {option.title}
              </div>
              <div className="flex flex-col rounded-md border border-gray-300 bg-white">
                {option.values.map((item) => (
                  <div className="font-caudex flex border-b border-gray-200 px-4 py-3 text-sm uppercase text-gray-800">
                    {item}
                  </div>
                ))}
                {option.values.length < 4 && (
                  <div className="font-caudex m-1 rounded-md bg-gray-300 p-4 text-lg text-black">
                    Upgrade
                    <br /> to access
                  </div>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export function PlansGridList(plan: PlanDataShape) {
  return (
    <div className="flex flex-col gap-4 rounded-md border border-gray-300 text-center">
      <Heading kind="h5" className="px-12 py-6 "></Heading>
    </div>
  );
}

export function PlanOptions({ options }: { options: PlanOptionInterface[] }) {
  return options.map((option) => (
    <div className="font-nunito grid grid-cols-[360px_1fr] gap-4 border-b border-gray-300 py-4 text-sm last:border-b-0">
      <div>{option.title}</div>
      <div className={`grid ${gridMap[option.values.length]} gap-4`}>
        {option.values.map((value) => (
          <div className="flex justify-center">
            {typeof value === 'boolean' ? (
              value ? (
                <CheckCircleIcon className="text-primary-600 h-4 w-4" />
              ) : (
                '-'
              )
            ) : (
              ''
            )}
            {typeof value === 'string' && value}
          </div>
        ))}
      </div>
    </div>
  ));
}

export function PlansAndPricesView() {
  const tabs = [
    { label: 'Plans', children: <PlansTab /> },
    { label: 'Prices', children: <PricesTab /> },
  ];
  return (
    <div className="relative">
      <PlansTab />
      {/* <TabsGeneric items={tabs} hugTabs /> */}
    </div>
  );
}
