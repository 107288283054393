import { CaseflowCaseTypes } from '@rabbit/bizproc/core';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import {
  Config_ClaimActionsType,
  Config_ClaimCosts_LogType,
  Config_ClaimDetailsSectionType,
  Config_ClaimDetailViewType,
  Config_ClaimsHoldingForms,
  Config_ClaimsTableType,
  Config_CountryTaxLabel,
  Config_PartnerTypeOptions,
  Config_RegistrationDetailsView_WarrantyDetailsSectionType,
  Config_RegistrationTableType,
  Config_UpdateClaimDetails_ClaimDetailsFormType,
  Config_UpdateClaimDetails_CustomerFormType,
  RestrictedUserPathsType,
  SAGE_ROUTE_NAME,
} from './types/config-types';

// Please read documentation/config.md for more info

const BaseConfig = {
  NAVIGATION: {
    SAGE: {
      DEFAULT_PATH: SAGE_ROUTE_NAME.REGISTRATIONS,
      DEFAULT_PARTNER_PATH: SAGE_ROUTE_NAME.REGISTRATIONS,
      PARTNER_RESTRICTED_PATH: [
        SAGE_ROUTE_NAME.REGISTRATIONS,
        SAGE_ROUTE_NAME.CLAIMS,
        SAGE_ROUTE_NAME.MANAGE,
        SAGE_ROUTE_NAME.WARRANTY_OVERVIEW,
      ] as string[],
    },
    RESTRICTED: false,
    RESTRICTED_PATHS: [] as string[],
    RESTRICTED_USER_PATHS: {
      Warrantor: [],
      ExternalRepairer: [],
    } as RestrictedUserPathsType,
  },
  DASHBOARD: {},
  CLAIMS: {
    CASEFLOW_TYPE: CaseflowCaseTypes.LITE,
    PROXY_OWNER_PERSONA: PersonaTypeSingleLetter.Warrantor,
    INTERNAL_REPAIRERS_ENABLED: false,
    CLAIMS_LIST_TABLE: {
      ENABLED: [
        PersonaTypeSingleLetter.Warrantor,
        PersonaTypeSingleLetter.Repairer,
      ] as PersonaTypeSingleLetter[],
      CAN_VIEW_ALL_CLAIMS: [
        PersonaTypeSingleLetter.Warrantor,
      ] as PersonaTypeSingleLetter[],
      ASSIGNED_UNASSIGNED_TABS: false,
    },
    OPERATING_PERSONAS: [
      PersonaTypeSingleLetter.Repairer,
      PersonaTypeSingleLetter.Warrantor,
    ],
    CLAIMS_FLOW: {
      CAN_ASSIGN_REPAIRER: [] as PersonaTypeSingleLetter[],
      SERIAL_NUMBER: false,
      SERIAL_NUMBER_PROOF: false,
      CREATE_WITH_WARRANTY_INFO: false,
      CREATE_HOLDING_FORM: 'BASE' as Config_ClaimsHoldingForms,
      CLAIMS_TABLE_TYPE: 'TYPE1' as Config_ClaimsTableType,
      NEW_CLAIM: {
        NEW_USER_BTN: true,
        NEW_PRODUCT_BTN: true,
      },
      INVOICE: {
        ENABLED: false,
      },
    },
    CHAT: {
      IS_READONLY: false,
    },
    CLAIM_DETAIL_VIEW: {
      CLAIM_DETAILS: 'DEFAULT' as Config_ClaimDetailsSectionType,
      UPDATE_CLAIM_DETAILS: {
        ENABLED: [PersonaTypeSingleLetter.Warrantor],
        CUSTOMER_DETAILS: 'NONE' as Config_UpdateClaimDetails_CustomerFormType,
        CLAIM_DETAILS:
          'DEFAULT' as Config_UpdateClaimDetails_ClaimDetailsFormType,
        //ATTACHMENTS: 'DEFAULT' as UpdateClaimDetails_AttachmentsFormType,
      },
      CLAIM_ACTIONS: {
        ENABLED: [] as PersonaTypeSingleLetter[],
        TYPE: 'TYPE1' as Config_ClaimActionsType,
      },
      CLAIM_COSTS: {
        COST_LOGGING_FLOW: 'DEFAULT' as Config_ClaimCosts_LogType,
        SHOW_SECTION: [
          PersonaTypeSingleLetter.Warrantor,
        ] as PersonaTypeSingleLetter[],
        LIST_NON_CASE_ACTORS: false,
      },
      REPAIRER_DETAILS_SECTION: [] as PersonaTypeSingleLetter[],
      TYPE: 'DEFAULT' as Config_ClaimDetailViewType,
    },
    CONSUMER: {
      CAN_INITIATE_CLAIM: true,
    },
  },
  AUTHENTICATION: {},
  ACCOUNT: {
    TAX_LABEL: Config_CountryTaxLabel.VAT as Config_CountryTaxLabel,
    DEFAULT_TAX_RATE: '', // percentage, has to be string for the form, do not ask me why... tech debt.. https://iwarranty.atlassian.net/browse/DEV-1941
  },
  HOLDINGS: {
    REGISTRATION: {
      TABLE_TYPE: Config_RegistrationTableType.DEFAULT,
      SERIAL_NUMBER_REQUIRED: false,
    },
    NEW_REGISTRATION_FLOW: {
      NEW_USER_BTN: true,
      ADD_NEW_PRODUCT: false,
      UNIQUE_PRODUCT: false,
      SUBMISSION_PREVIEW:false,
      PROOF_OF_PURCHASE:false,
      RRP:false,
    },
    REGISTRATION_DETAILS_VIEW: {
      WARRANTY_DETAILS_SECTION:
        'DEFAULT' as Config_RegistrationDetailsView_WarrantyDetailsSectionType,
    },
    PRODUCT: {
      CUSTOM_CATEGORY: false,
    },
  },
  VENDABLES: {
    EDITOR: {
      ENABLED: [] as PersonaTypeSingleLetter[],
      CAN_ADD_NEW: [] as PersonaTypeSingleLetter[],
    },
    PART_SEARCH: {
      ENABLED: false,
    },
  },
  WARRANTIES: {
    MANAGEMENT: {
      SHOW_WARRANTY_OPTIONS_BTN: true,
      CAN_VOID_WARRANTIES: false,
      CAN_EDIT_WARRANTIES: false,
      CAN_EDIT_PRICE_PLANS: false,
      CAN_SET_WARRANTY_STATUS: false,
    },
  },
  MARKETING: {
    EMAIL_TEMPLATE_LANGUAGE: 'EN',
  },
  SHOPIFY: {
    ENABLED: false,
  },
  PARTNERS: {
    PARTNER_TYPE_OPTIONS: Config_PartnerTypeOptions.DEFAULT,
    REGISTRATION: {
      TC_REQUIRED: false,
    },
  },
};

export default BaseConfig;
